// src/components/Servicios.js
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const Servicios = () => {
  const servicios = [
    { id: 1, title: 'Venta de Propiedades', text: 'Mejor asesoría para la venta de inmuebles.' },
    { id: 2, title: 'Arriendos', text: 'Ofrecemos las mejores opciones de arriendo en la ciudad.' },
    { id: 3, title: 'Permuta de Propiedades', text: 'Asesoría completa en la permuta de inmuebles.' }
  ];

  return (
    <Row className="m-4">
      {servicios.map(servicio => (
        <Col key={servicio.id} md={4} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>{servicio.title}</Card.Title>
              <Card.Text>{servicio.text}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Servicios;
