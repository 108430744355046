import { FaBuilding } from 'react-icons/fa';
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './CaptacionDeClientes.css';


const CaptacionDeClientes = () => {
  return (
    <section className="captacion-clientes-section">
      <Container>
        <Row className="text-center">
          <h2 className="mb-4">PUBLICA YA TU INMUEBLE EN MENOS DE 5 MINUTOS</h2>
          <div className="custom-separator">
            <div className="line"></div>
              <div className="icon">
                <FaBuilding /> {/* Coloca el ícono aquí */}
              </div>
            <div className="line"></div>
          </div>
        </Row>
        <Row>
          {/* Columna para Inmobiliaria */}
          <Col md={6} className="text-white p-4">
            <div className="fondo-azul-0">
              <h3>Inmobiliaria</h3>
              <h4>Si eres inmobiliaria podrás</h4>
              <ul>
                <li>Publicar todos tus proyectos y modelos.</li>
                <li>
                  Acceder a tu área privada para gestionar tus anuncios (publicar, editar, activar, desactivar, eliminar, etc.).
                </li>
                <li>
                  Tener tu propia página con tu logo y enlace a tu web.
                </li>
              </ul>
              <Button variant="success" className="mt-3">Crea tu perfil de Inmobiliaria</Button>
            </div>
          </Col>

          {/* Columna para Agente */}
          <Col md={6} className="text-white p-4">
            <div className="fondo-azul-0">
              <h3>Agente</h3>
              <h4>Si eres agente podrás</h4>
              <ul>
                <li>Publicar todos tus inmuebles.</li>
                <li>
                  Acceder a tu área privada para gestionar tus anuncios (publicar, editar, activar, desactivar, eliminar, etc.).
                </li>
                <li>
                  Tener tu propia página con tu logo y enlace a tu web.
                </li>
              </ul>
              <Button variant="success" className="mt-3">Crea tu perfil de agente</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CaptacionDeClientes;
