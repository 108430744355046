import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Testimonios = () => {
  const [testimonios, setTestimonios] = useState([]);

  // Aquí llamarías a tu API cuando esté lista
  useEffect(() => {
    // Ejemplos de testimonios de muestra
    const testimoniosEjemplo = [
      {
        id: 1,
        nombre: "Carlos Sánchez",
        comentario: "Excelente servicio, muy profesionales y rápidos en la atención.",
        calificacion: 5
      },
      {
        id: 2,
        nombre: "Ana García",
        comentario: "Muy satisfecha con la compra de mi nueva casa. Todo el proceso fue muy sencillo.",
        calificacion: 4
      },
      {
        id: 3,
        nombre: "Pedro Martínez",
        comentario: "Recomiendo esta inmobiliaria, fueron atentos y resolvieron todas mis dudas.",
        calificacion: 5
      }
    ];

    // Simulamos una llamada a la API con los testimonios de prueba
    setTestimonios(testimoniosEjemplo);
  }, []);

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Testimonios de Clientes</h2>
      <Row>
        {testimonios.map(testimonio => (
          <Col md={4} key={testimonio.id} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{testimonio.nombre}</Card.Title>
                <Card.Text>{testimonio.comentario}</Card.Text>
                <div>
                  {"★".repeat(testimonio.calificacion)}{"☆".repeat(5 - testimonio.calificacion)}
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Testimonios;
