import React from 'react';
import './BannerInformativo.css'; // <div className="banner-content"> <h1>Contacto inmobiliario</h1></div>


function BannerPSE() {
  return (
    <div className="banner-informativo">
      
    </div>
  );
}

export default BannerPSE;