import ImagenAgente from '../assets/images/agente_inmobiliario_contacto.png'; // Imagen circular que usaste
import React, { useState } from 'react';
import './FormularioContacto.css'; // Archivo CSS importado

const FormularioContacto = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    comentarios: '',
    acepto: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica para enviar el formulario
    console.log('Formulario enviado', formData);
  };

  return (
    <div className="formulario-contacto">
      {/* Fondo azul */}
      <div className="fondo-azul"></div>

      {/* Contenido principal */}
      <div className="container contenido">
        <div className="row align-items-center">
          {/* Columna 1 - Texto */}
          <div className="col-md-4 texto-inmueble">
            <h1>¿Busca vender o arrendar su inmueble?</h1>
            <p>
              Establecemos relaciones de confianza para arrendar o vender su inmueble con las mejores prácticas del mercado.
            </p>
          </div>
          
          {/* Columna 2 - Imagen */}
          <div className="col-md-4 text-center">
            <img src={ImagenAgente} alt="Inmueble" className="img-fluid" />
          </div>

          {/* Columna 3 - Formulario */}
          <div className="col-md-4 formulario-contacto-form">
            <h2>Oferte su inmueble con nosotros</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="nombre">Nombre</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="telefono">Teléfono</label>
                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="comentarios">Comentarios</label>
                <textarea
                  id="comentarios"
                  name="comentarios"
                  value={formData.comentarios}
                  onChange={handleChange}
                  className="form-control"
                  rows="4"
                />
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="acepto"
                  name="acepto"
                  checked={formData.acepto}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor="acepto" className="form-check-label">
                  Acepto recibir información de ofertas de inmuebles
                </label>
              </div>
              <button type="submit" className="btn btn-primary mt-3">
                Enviar formulario
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormularioContacto;
