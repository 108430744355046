import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios'; // Axios para realizar la petición HTTP


// Componente individual para mostrar una propiedad
const TarjetaPropiedad = ({ propiedad }) => {
const RUTA= 'http://147.79.75.210:8090/'

  return (
       <Col md={4} className="mb-4">
      <Card>
        <Card.Img
          variant="top"
          src={`${RUTA}${propiedad.propertyImg[0].path }`}
          alt={propiedad.title}
        />
        <Card.Body>
          <Card.Title>{propiedad.title}</Card.Title>
          <Card.Text>
            <strong>Ciudad:</strong> {propiedad.city.name}
          </Card.Text>
          <Card.Text>
            <strong>Barrio:</strong> {propiedad.neighborhood.name} 
          </Card.Text>
          <Card.Text>
            <strong>Propiedad :</strong> {propiedad.propertyType.name}
          </Card.Text>
          <Card.Text>
            <strong>Valor:</strong> {propiedad.saleValue} COL
          </Card.Text>
          <Card.Text>
            <strong>Area :</strong> Auton{propiedad.lotArea} m²
          </Card.Text>

          
        </Card.Body>
      </Card>
    </Col>
  );
};

// Componente principal que carga y muestra las propiedades
const PropiedadesDestacadas = () => {
  const [propiedades, setPropiedades] = useState([]); // Estado para almacenar propiedades
  const [loading, setLoading] = useState(true); // Estado para manejar el estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores

  // Hook para realizar la consulta cuando el componente se monta
  useEffect(() => {
    const fetchPropiedades = async () => {
      try {
        const response = await axios.post('http://147.79.75.210:8090/api/propertyPublic/search/1?page=0&pageSize=10'); // Reemplaza con tu URL de endpoint real
        console.log(response);
        setPropiedades(response.data.results); // Asignamos los datos al estado
      } catch (error) {
        setError('Error al cargar propiedades'); // Manejamos errores
      } finally {
        setLoading(false); // Finalizamos la carga
      }
    };

    fetchPropiedades(); // Ejecutamos la función para cargar propiedades
  }, []); // [] asegura que se ejecute solo una vez cuando el componente se monta

  // Mostrar spinner mientras se cargan las propiedades
  if (loading)
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </Spinner>
      </div>
    );

  // Mostrar mensaje de error si ocurre
  if (error) return <Alert variant="danger">{error}</Alert>;

  // Renderizar las propiedades
  return (
    <Row className="m-4">
      {propiedades.length > 0 ? (
        propiedades.map((propiedad,index) => (
          <TarjetaPropiedad key={index} propiedad={propiedad} />
        ))
      ) : (
        <p>No hay propiedades disponibles</p>
      )}
    </Row>
  );
};

export default PropiedadesDestacadas;
