import React from 'react';
import './BannerPSE.css'; // Importamos los estilos
import ImagePSE from '../assets/images/Boton_PSE_cabrera_rojas_inmobiliaria.png'; // Imagen circular que usaste

const BannerPSE = () => {
  return (
    <div className="banner-pse">
      <div className="circle">
        <img src={ImagePSE} alt="Pagos Seguros en Línea" />
        <div className="tooltip">
          <a href="https://customers.ecollect.co/html/10340870/ecollect10340870.html" target="_blank" rel="noopener noreferrer">
            Pagos seguros en línea
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerPSE;
