import React from 'react';
import './Footer.css'; // Estilos locales del footer  <p><a href="/contacto">Contacto</a> | <a href="/blog">Blog</a></p>

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 Inmobiliaria Cabrera Rojas. Todos los derechos reservados.</p>
       
      </div>
    </footer>
  );
}

export default Footer;
