import React from 'react';
import BannerInformativo from '../components/BannerInformativo.js';
import Servicios from '../components/Servicios';
import PropiedadesDestacadas from '../components/PropiedadesDestacadas';
import BannerPSE from '../components/BannerPSE'; // Verifica que la ruta sea correcta
import FormularioContacto from '../components/FormularioContacto';
import CaptacionDeClientes from '../components/CaptacionDeClientes';
import Testimonios from '../components/Testimonios.js';
import Info from '../components/Info.js';
import { Container } from 'react-bootstrap';

function Home() {
  return (
    <div>
      <BannerInformativo />
      <Servicios />
      <Container>
        <h1>Propiedades Destacadas</h1>
        <PropiedadesDestacadas /> {/* Renderizamos el componente */}
      </Container>
      <BannerPSE />
      <Testimonios />
      <FormularioContacto />
      <CaptacionDeClientes />
      <Info />
    </div>
  );
}

export default Home;
