import './Info.css';
import logo from '../assets/images/logo.png'; // Logo de la empresa
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Info = () => {
  return (
    <div className="info-component" style={{ backgroundColor: ' #F2F2F2', padding: '20px' }}>
      {/* Línea separadora */}
      <div style={{ borderTop: '3px solid #007bff', marginBottom: '20px' }}></div>
      
      <Container>
        {/* Primera Fila */}
        <Row className="mb-4">
          <Col md={3}>
            <Image src={logo} className="info-logo-image" alt="Grupo Cabrera Rojas Logo" fluid />
            <h2 className="info-logo-text">CABRERA ROJAS S.A.S</h2>
          </Col>
          <Col md={3}>
            <h5 style={{ fontWeight: 'bold' }}>Sobre Grupo Cabrera Rojas SAS</h5>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>Quienes somos</li>
              <li>Trabaja con nosotros</li>
              <li>Blog</li>
            </ul>
          </Col>
          <Col md={3}>
            <h5 style={{ fontWeight: 'bold' }}>Contacto</h5>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>Contacto</li>
              <li>Publica tus inmuebles</li>
              <li>Términos y condiciones</li>
              <li>Política de privacidad</li>
            </ul>
          </Col>
          <Col md={3}>
            <h5 style={{ fontWeight: 'bold' }}>Redes Sociales</h5>
            <div className="social-icons">
              <a href="https://facebook.com" style={{ marginRight: '10px' }}><FaFacebook size={24} color="#253743" /></a>
              <a href="https://instagram.com" style={{ marginRight: '10px' }}><FaInstagram size={24} color="#253743" /></a>
              <a href="https://twitter.com" style={{ marginRight: '10px' }}><FaTwitter size={24} color="#253743" /></a>
              <a href="https://linkedin.com"><FaLinkedin size={24} color="#253743" /></a>
            </div>
          </Col>
        </Row>

        {/* Segunda Fila */}
        <Row>
         <Col md={12}>
            <h5 style={{ fontWeight: 'bold' }}>Ciudades en las que operamos</h5>
            <p>Cali     Palmira     Cerrito</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Info;
