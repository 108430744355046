import React from 'react';
import './Header.css';
import logo from '../assets/images/logo.png'; // Logo de la empresa

function Header() {
  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Cabrera Rojas Logo" className="logo-image" />
        <h1 className="logo-text">CABRERA ROJAS S.A.S</h1>
      </div>
      <nav className="nav-menu">
        <ul>
          <li><a href="/">Inicio</a></li>
          <li><a href="/ventas">Ventas</a></li>
          <li><a href="/alquiler">Alquiler</a></li>
          <li><a href="/permuta">Permuta</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="/juridico">Jurídico</a></li>
          <li><a href="/contacto">Contacto</a></li>
          <li><a href="http://inmomanagen.com/">Portal</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
